module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"order.baby 👶📝","short_name":"order.baby","lang":"en","start_url":"/","background_color":"#FFF","theme_color":"#FFF","display":"standalone","icon":"src/images/site-icon.png","icons":[{"src":"/src/images/site-icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/src/images/site-icon-512x512.png","sizes":"512x512","type":"image/png"}],"er_related_applications":true,"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"5c13f37492b2e600f71caa0eeab71108"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
